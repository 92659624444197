<template>
	<div class='course-content'>
		<div class='course-top overflow'>
			<div class="float-left" >
				<div class="course-title">数据统计</div>
			</div>
			<div class="float-right" >
				<button class="back-btn" type="button" @click="edit">
					<span><img src="../assets/img/no-edit.png"></span>
					<span>返回</span>
				</button>
			</div>
		</div>
		<div class='overflow statistics' v-loading="infoLoading" >
			<div :style="item.imageUrl" v-for="(item,index) in statisticsList" class="statistics-item">
				<span class="statistics-item-title">{{item.number}}</span>
				<span class="statistics-item-content">{{item.title}}</span>

			</div>
		</div>
		<div class='course-top overflow ' style="margin-top: 20px;height: 50px;">
			<div class="float-right" >
				<el-input placeholder="输入单位名称进行搜索" class="search-input" v-model="search"></el-input>
				<button class="search-btn" type="button" @click="searchList"><i class="el-icon-search"></i>搜索</button>
				<button class="reset-btn" type="button" @click="reset">重置</button>
			</div>
		</div>



		<div>
			<div class="table-content"  v-loading="loading">
				<el-table :data="tabList" border stripe
					:header-cell-style="{background: '#EEF7FE',color:'black'}">
					<el-table-column label="序号" align="center" type="index" width="60">
					</el-table-column>
					<el-table-column prop="affiliation" label="单位名称" align="center" min-width="590"> </el-table-column>
					<el-table-column prop="staff_count" label="参赛人数" align="center" min-width="90"> </el-table-column>
					<el-table-column prop="course_count" :label="region_theme_text + '总数'" align="center" min-width="90"> </el-table-column>

					<!-- <el-table-column label="操作" width="150" align="center" fixed="right">
						<template slot-scope="scope">
							<el-button type="text" style="color: #44D89E;" @click='detail_click(scope.row)'>详情
							</el-button>
							<el-button v-if="scope.row.relate_own_competition_course_comment == null" type="text"
								@click='review_store(scope.row)' style="color: #FF4E00;">评分</el-button>
							<el-button v-else type="text" style="color: #1FB4F9;" @click='review_update(scope.row)'>修改评分
							</el-button>
						</template>
					</el-table-column> -->
				</el-table>
			</div>
			<div class="block">
				<el-pagination v-if='paginationShow' background @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page="currentPage">
				</el-pagination>
			</div>
		</div>
		<courseDetail ref="courseDetail" role="review"></courseDetail>
	</div>
</template>

<script>
	import courseDetail from '../components/courseDetail.vue'
	import {
		reactive,
		ref,
		toRefs,
		watchEffect
	} from 'vue'
	import {
		useRouter
	} from 'vue-router' //引用router
	export default {
		name: 'index',
		components: {
			courseDetail
		},
		data() {
			return {
				region_theme_text: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				tabList: [],
				pageSize: 5,
				total: 1,
				currentPage: 1,
				paginationShow: false,
				search: '',
				infoLoading: false,
				loading: false,
				statisticsList: [{
					number: 0,
					title: (JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程') + "总数",
					imageUrl: {
						width: "220px",
						backgroundSize: "100% 100%",
						backgroundImage: "url(" + require("@/assets/img/statistics-bg-1.png") + ")",
					}
				}, {
					number: 0,
					title: "审核通过",
					imageUrl: {
						width: "220px",
						backgroundSize: "100% 100%",
						backgroundImage: "url(" + require("@/assets/img/statistics-bg-2.png") + ")",
					}
				}, {
					number: 0,
					title: "审核未通过",
					imageUrl: {
						width: "220px",
						backgroundSize: "100% 100%",
						backgroundImage: "url(" + require("@/assets/img/statistics-bg-3.png") + ")",
					}
				}, {
					number: 0,
					title: "参加单位数",
					imageUrl: {
						width: "220px",
						backgroundSize: "100% 100%",
						backgroundImage: "url(" + require("@/assets/img/statistics-bg-4.png") + ")",
					}
				}, {
					number: 0,
					title: "参加教师数",
					imageUrl: {
						width: "220px",
						height: "100px",
						backgroundSize: "100% 100%",
						backgroundImage: "url(" + require("@/assets/img/statistics-bg-5.png") + ")",
					}
				}, ],


			}
		},
		methods: {
			//返回
			edit() {
				this.$router.push({
					name: 'govermentList'
				})
			},

			//分页
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				// sessionStorage.setItem('currentPage', val);
				this.getList();
			},
			// 搜索
			searchList() {
				this.currentPage = 1
				this.getList()
			},
			// 重置
			reset() {
				this.search = ''
				this.currentPage = 1
				this.getList()
			},
			getList() {
				// if (sessionStorage.getItem('review') && sessionStorage.getItem("currentPage")) {
				// 	//如果有这个就读取缓存里面的数据
				// 	this.currentPage = Number(sessionStorage.getItem("currentPage"));
				// }
				let obj = this.util.delObjItem({
					page: this.currentPage,
					limit: this.pageSize,
					sf2: this.search
				})
				this.loading = true
				this.http.exportGovernmentStatisticsList('?region_code=' + JSON.parse(sessionStorage.getItem(
					'region_code')), obj).then((res) => {
					this.tabList = res.data.data.list
					// this.tabList=this.tabList.concat(this.tabList);
					this.loading = false
					this.total = res.data.data.total
					this.paginationShow = true
				})
			},
			//获取基本数据
			getDataInfo(){
				this.infoLoading = true
				this.http.exportGovernmentStatisticsInfo('?region_code=' + JSON.parse(sessionStorage.getItem(
					'region_code'))).then((res) => {
					let info = res.data.data
					this.infoLoading = false
					this.statisticsList[0].number=info.course_count;
					this.statisticsList[1].number=info.course_confirmed_count;
					this.statisticsList[2].number=info.course_unconfirmed_count;
					this.statisticsList[3].number=info.course_affiliation_count;
					this.statisticsList[4].number=info.course_staff_count;
				})
			}

		},
		mounted() {
			this.getList()
			this.getDataInfo();
		},
		destroyed() {
			sessionStorage.removeItem("review")
		},
		watch: {

		}
	}
</script>

<style scoped="scoped">
	.course-content {
		width: 68%;
		height: 73vh;
		min-width: 1200px;
		margin: 0 auto;
		background-color: white;
		padding: 20px 10px 20px 25px;
		box-sizing: border-box;
		overflow-y: scroll;
	}

	.course-top {
		height: 40px;
		margin-bottom: 10px;
	}

	.course-title {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		letter-spacing: 1px;
		margin-bottom: 15px;
	}

	.el-table td,
	.el-table th {
		padding: 8px 0 !important;
	}

	.table-content {
		/* min-height: 49vh;
		max-height: 48vh; */
		/* min-height: 49vh; */
		/* overflow-y: scroll; */
	}

	.table-content /deep/ .el-table td {
		padding: 6px 0 !important
	}

	.table-content /deep/ .el-table th {
		padding: 6px 0 !important
	}

	/* 分页 */
	.block {
		text-align: center;
		margin-top: 20px;
	}

	/*表格hover*/
	/deep/.el-tag {
		border-radius: 0;
		background: none;
		border: none;
		color: black;
		font-size: 14px;
		/*line-height: 60px;*/
	}

	.search-input {
		width: 400px;
		height: 40px;
		margin-right: 30px;
	}

	.search-input /deep/ .el-input__inner {
		height: 38px;
		line-height: 38px;
	}

	.search-btn {
		width: 80px;
		height: 40px;
		background: #FF3B3D;
		box-shadow: 0px 3px 6px rgba(255, 59, 61, 0.16);
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		color: #ffffff;
		border: none;
		margin-right: 20px;
		cursor: pointer;
	}
	.search-btn:hover {
		background: #C6191F;
	}
	.search-btn i {
		margin-right: 5px;
	}

	.reset-btn {
		box-sizing: border-box;
		width: 80px;
		height: 40px;
		/* background: rgba(255, 255, 255, 0.39); */
		background: #E8E8E8;
		/* box-shadow: 0px 3px 6px #FF3B3D; */
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		/* color: #FF3B3D; */
		color: #333333;
		border: none;
		/* border: 1px solid #FF3B3D; */
		cursor: pointer;
	}
	.reset-btn:hover {
		background: #D1D1D1;
	}
	.back-btn {
		box-sizing: border-box;
		/* width: 80px; */
		padding: 0 20px;
		height: 40px;
		line-height: 40px;
		/* background: rgba(255, 255, 255, 0.39); */
		background: #E8E8E8;
		/* box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500); */
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		/* color: #FF3B3D; */
		color: #333333;
		border: none;
		cursor: pointer;
	}
	.back-btn:hover {
		background: #D1D1D1;
	}
	.back-btn img {
		margin-right: 10px;
		width: 13px;
		height: 13px;
		margin-top: 2px;
	}

	.icon-style {
		color: #ffffff;
		background-color: #999999 !important;
		border-radius: 50%;
		margin-left: 1px;
	}

	.statistics {
		display: flex;
		justify-content: space-between;
		color: #FFFFFF;
	}

	.statistics-item {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 10px;
	}

	.statistics-item-title {
		/* font-weight: bold; */
		font-size: 26px;
	}

	.statistics-item-content {
		font-size: 14px;
	}
</style>
